
import { defineComponent, ref, onMounted, watchEffect, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService from "@/core/services/AppService";
import { News, NewsDefault } from "@/store/model/News";
import { CategoryDefault, categoryModel } from "@/store/model/Category";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "add-partner-category",
  props: ["id"],
  components: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const listDataNewsCategory = ref([]);
    let objectDetail = ref<categoryModel>(
      JSON.parse(JSON.stringify(CategoryDefault))
    );
    let account_current = ref({});
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Tạo  mới", ["Danh mục tin tức"]);
      await store.dispatch(
        Actions.GET_ALL_NEWS_CATEGORY,
        '?search={"type":"news"}'
      );
      listDataNewsCategory.value = store.getters["listDataCategory"];
      account_current.value = store.getters["currentUser"];
    });
    const rules = ref({
      name: [
        {
          required: true,
          message: "Tên danh mục không được để trống",
          trigger: "blur",
        },
      ],
    });
    const convertNameToSlug = (value) => {
      objectDetail.value["slug"] = AppService.convertSlug(value);
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          delete objectDetail.value["_id"];
          store
            .dispatch(Actions.ADD_PARTNER_CATEGORY, objectDetail.value)
            .then(() => {
              Swal.fire({
                text: "Thêm mới thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(function (result) {
                if (result.isConfirmed) {
                  router.push({ name: "partners_categories" });
                }
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };
    return {
      rules,
      submit,
      convertNameToSlug,
      loading,
      objectDetail,
      listDataNewsCategory,
      formRef,
    };
  },
});
